/* eslint-disable import/extensions */

import Web from "../assets/images/Services/Web.png";
import outdooradvertisement from "../assets/images/Services/outdooradvertisement.png";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";
import Brand from "../assets/images/Services/Brand.png";
import digitalmarketing from "../assets/images/Services/Digitalmarketing.png";

// Portfolio Img Imports
import kadai from "../assets/images/Portfolio/Maduraikadai.jpg";
import fooddeliverypos from "../assets/images/Portfolio/foodelivery.png";
import carrental from "../assets/images/Portfolio/carrental.jpg";
import pgsapp from "../assets/images/Portfolio/pgsapp.jpg";
import landcube from "../assets/images/Portfolio/landcube.jpg";
import Arasan from "../assets/images/Portfolio/Arasan.jpg";
import expensify from "../assets/images/Portfolio/Expensify.jpg";
import Stream from "../assets/images/Portfolio/Balajigasapp.jpg";
import Freelance from "../assets/images/Portfolio/smjewellers.jpg";
import balajiweb from "../assets/images/Portfolio/balajiweb.jpg";
import Jupiter from "../assets/images/Portfolio/Jupiter.jpg";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";
import posrestraunt from "../assets/images/Portfolio/POSrestarunt.jpg";
import crm from "../assets/images/Portfolio/CRM.jpg";
import jupiterdigitals from "../assets/images/Portfolio/Jupiterdigitals.jpg";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

import aariya from "../assets/images/TeamMembers/aariya new logo.png";
import ashvattha from "../assets/images/TeamMembers/Ashvattha Logo.png";
import Balajifurniture from "../assets/images/TeamMembers/Balaji Furniture Logo.jpg";
import ccellpark from "../assets/images/TeamMembers/Cell Park Logo.png";
import Dreamz from "../assets/images/TeamMembers/Dreamz logo (2).png";
import dharma from "../assets/images/TeamMembers/DS Logo.png";
import hyrox from "../assets/images/TeamMembers/Hydrox Logo HD.png";
import Iris from "../assets/images/TeamMembers/Iris Logo.png";
import Kiara from "../assets/images/TeamMembers/Kiara Logo.png";
import kids from "../assets/images/TeamMembers/Kids o Kids Logo.png";
import MaduraiKadai from "../assets/images/TeamMembers/Madurai Kadai logo HD.png";
import mobilebazar from "../assets/images/TeamMembers/Mobile Bazar Logo.png";

export const Services = [
  {
    title: "WEB DEVELOPMENT",
    imageUrl: Web,
    animation: "left",
  },
  {
    title: "APP DEVELOPMENT",
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: "GRAPHICS & UI UX DESIGN",
    imageUrl: UIUX,
    animation: "right",
  },
  {
    title: "DIGITAL MARKETING",
    imageUrl: digitalmarketing,
    animation: "left",
  },
  {
    title: "BRANDING",
    imageUrl: Brand,
    animation: "up",
  },
  {
    title: "CORPRATE AD FLIM MAKING",
    imageUrl: outdooradvertisement,
    animation: "right",
  },
];

export const Portfolios = [
  {
    id: "landqube",
    title: "LAND CUBE",
    imageUrl: landcube,
    contents:
      "LandCube is a comprehensive real estate website designed to streamline property searches and connect buyers, sellers, and renters. The platform offers a user-friendly experience with advanced search filters, allowing users to explore listings based on location, property type, price, and amenities. LandCube provides detailed property descriptions, high-quality images, virtual tours, and interactive maps to help users make informed decisions. With features like real-time availability updates, neighborhood insights, and expert guidance, LandCube simplifies the property discovery process, making it easy for users to find their ideal home or investment property.",
    type: "Website",
    responsibility: ["Web Development"],
    credit: "",
  },
  {
    id: "jupiterdigitals",
    title: "JUPITER DIGITALS",
    imageUrl: jupiterdigitals,
    contents:
      "Jupiter Digitals is an innovative IT company specializing in delivering customized digital solutions for businesses of all sizes. Our website showcases a range of services, including web and mobile app development, digital marketing, cloud computing, and software consulting. With a team of skilled professionals, Jupiter Digitals is dedicated to transforming ideas into powerful digital experiences that drive growth and efficiency. Explore our portfolio, read client success stories, and discover how we can help elevate your business in today’s digital landscape.",
    type: "Website",
    responsibility: ["Web Development"],
    credit: "",
  },
  {
    id: "salesmanagement",
    contents:
      "Sales Management CRM is a powerful tool designed to streamline and optimize the sales process within an organization. This CRM system helps businesses manage customer relationships, track leads, and monitor sales activities in real-time. It centralizes data on prospects, contacts, and sales opportunities, enabling sales teams to engage with customers more effectively and close deals faster. With features like automated follow-ups, sales forecasting, performance analytics, and customizable dashboards, Sales Management CRM empowers teams to increase productivity, improve customer satisfaction, and ultimately drive revenue growth.",
    title: "SALES MANAGEMENT",
    imageUrl: crm,
    type: "crm",
    responsibility: ["CRM"],
  },
  {
    id: "restraunt",
    contents:
      "A Restaurant POS (Point of Sale) system is a software solution designed for managing orders, payments, and overall operations within a restaurant. It streamlines workflows by handling order tracking, inventory management, table reservations, and customer transactions in real-time. With features like billing, kitchen display integration, and sales analytics, a Restaurant POS enhances efficiency, reduces errors, and helps improve customer service.",
    title: "RESTAURANT",
    imageUrl: posrestraunt,
    type: "pos",
    responsibility: ["POS"],
  },
  {
    id: "arasan",
    title: "ARASAN",
    imageUrl: Arasan,
    contents:
      "The Arasan Sales Automation application is a comprehensive tool for sales tracking and expense management, tailored to enhance the efficiency of sales operations. This application features both admin and sales domains, allowing seamless interaction between managers and sales personnel. Admins have live monitoring capabilities to track the locations visited by sales representatives in real time, providing valuable insights into their field activities. The app includes an automatic live location marking feature for sales visits, enabling accurate tracking. Additionally, sales personnel can record their attendance directly through the app and conveniently log food and travel expenses, ensuring accurate expense reporting. For added convenience, admins can download detailed location reports of sales personnel for specific dates, offering a complete overview of field operations and expenses.",
    type: "Mobile Apps",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "balajigas",
    title: "BALAJI GAS",
    imageUrl: Stream,
    type: "Mobile Apps",
    responsibility: ["Mobile Development"],
    contents:
      "The Balaji Gas application is a streamlined solution for managing gas cylinder orders and deliveries, encompassing three main domains: Admin, Employees, and Customers. Within the Admin domain, admins can efficiently organize and assign customer orders to employees, ensuring smooth operations. Employees, in turn, can update delivery details, providing real-time status on each customer’s order. Customers have a simple, user-friendly interface where they can easily book gas cylinders, which is the core function of the application. This integrated approach simplifies the order and delivery process, ensuring a reliable and efficient service for all users.",
    credit: "",
  },
  {
    id: "smjewellers",
    title: "SM JEWELLERY",
    imageUrl: Freelance,
    type: "Mobile Apps",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "expense",
    title: "EXPENSIFY",
    imageUrl: expensify,
    type: "Mobile Apps",
    contents:
      "Expensify is an app designed to help you track and calculate your day-to-day expenses, giving you a clear view of your savings.",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "pgsapp",
    title: "PGS SALES FORCE",
    imageUrl: pgsapp,
    type: "Mobile Apps",
    contents:
      "PGS Sales Force is a comprehensive app designed to streamline sales operations across three main domains: Super Admin, Telecaller, and Sales. The Telecaller domain enables telecallers to reach out to customers, gather essential information, and enter these details directly into the app. The Super Admin oversees the entire process, assigning customer details to telecallers and managing workflow efficiently. The Sales domain utilizes the data provided by Telecallers to follow up with leads and close sales, ensuring a smooth, coordinated sales process from customer engagement to final sale.",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "madurakadai",
    title: "MADURAI KADAI",
    imageUrl: kadai,
    type: "Mobile Apps",
    contents:
      "Our Madurai Kadai app is designed to offer an engaging shopping experience with a wide range of features to make buying items convenient and enjoyable. From browsing an extensive selection of products to seamless purchasing options, our app aims to attract customers and enhance their shopping journey. The primary goal of Madurai Kadai is to provide a user-friendly platform that encourages customers to explore, discover, and purchase items effortlessly.",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "fooddelivery",
    title: "FOOD DELIVERY",
    imageUrl: fooddeliverypos,
    type: "pos",
    contents:
      "PGS Sales Force is a comprehensive app designed to streamline sales operations across three main domains: Super Admin, Telecaller, and Sales. The Telecaller domain enables telecallers to reach out to customers, gather essential information, and enter these details directly into the app. The Super Admin oversees the entire process, assigning customer details to telecallers and managing workflow efficiently. The Sales domain utilizes the data provided by Telecallers to follow up with leads and close sales, ensuring a smooth, coordinated sales process from customer engagement to final sale.",
    responsibility: ["Mobile Development"],
    credit: "",
  },
  {
    id: "carrental",
    title: "CAR RENTAL",
    imageUrl: carrental,
    type: "Mobile Apps",
    contents:
      "Our Car Rental app allows customers to easily book a car for rent, offering convenience and flexibility for their travel needs. With a user-friendly interface, customers can browse available vehicles, choose the one that best suits their preferences, and complete their booking directly through the app. Whether it's for a short trip or a long journey, the app ensures a smooth rental experience, with flexible pick-up and drop-off options, all designed to provide maximum comfort and convenience during your travels.",
    responsibility: ["Mobile Development"],
    credit: "",
  },

  {
    id: "balajiwebgas",
    title: "BALAJI GAS",
    imageUrl: balajiweb,
    contents:
      "Welcome to Balaji Gas, your trusted provider of gas solutions. On this page, you'll find all the essential details about our Madurai agency, including the services we offer, our contact information, and our commitment to providing reliable and safe gas solutions for households and businesses alike. Whether you're looking for a new gas connection, refills, or any other related services, our Madurai agency is here to serve you with the best in customer service and high-quality gas products. We pride ourselves on ensuring the convenience and safety of our customers with every transaction. Explore our offerings and get in touch for all your gas needs!",
    type: "Website",
    responsibility: ["Web Development"],
    credit: "",
  },
  {
    id: "jupiterweb",
    title: "JUPITER CRACKERS",
    imageUrl: Jupiter,
    contents:
      "Welcome to Jupiter Crackers, your one-stop online destination for purchasing premium crackers at unbeatable offer prices. Our dynamic website brings you a wide range of crackers, from sparklers to high-end fireworks, all available at discounted rates. Whether you're celebrating a festival or planning a special event, we ensure you get the best deals without compromising on quality. With easy navigation, secure payment options, and fast delivery, shopping for crackers has never been more convenient. Stay updated with our exclusive offers and promotions to enjoy incredible savings on your favorite crackers. Explore our collection and make your celebrations brighter with Jupiter Crackers!",
    type: "Website",
    responsibility: ["Web Development"],
    credit: "",
  },
  {
    id: "sanitaryweb",
    title: "SANITARY WORLD IN MADURAI",
    imageUrl: ManagementApp,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/28Rto9JjTCA",
  },
];

export const Advantages = [
  [
    {
      title: "Attention to Detail",
      description:
        "We believe that every pixel matters. Our meticulous attention to detail ensures that every design element is carefully crafted for maximum impact.",
      imageUrl: Collaborative,
    },
    {
      title: "Creativity",
      description:
        "Our designers are passionate about pushing the boundaries of creativity to deliver designs that stand out.",
      imageUrl: Communicative,
    },
  ],
  [
    {
      title: "Collaborative Process",
      description:
        "We believe in collaboration and work closely with you throughout the design process to ensure that your vision is brought to life.",
      imageUrl: Favorite,
    },
    {
      title: "User-Centric Approach",
      description:
        "We prioritize the needs and preferences of your target audience to create designs that resonate with them.",
      imageUrl: Management,
    },
  ],
];

export const TeamMembers = [
  {
    name: "Aarya Arisi",
    // position: 'CEO',
    imageUrl: aariya,
  },
  {
    name: "Ashvattha",
    // position: 'HRD',
    imageUrl: ashvattha,
  },
  {
    name: "Balaji furniture",
    // position: 'Finance',
    imageUrl: Balajifurniture,
  },
  {
    name: "Cell Park",
    // position: 'Project Manager',
    imageUrl: ccellpark,
  },
  {
    name: "Dreamz Beauty Care",
    // position: 'Front-end Developer',
    imageUrl: Dreamz,
  },
  {
    name: "Dharma Sastha Jewllers",
    // position: 'Front-end Developer',
    imageUrl: dharma,
  },
  {
    name: "Hyrox Fitnexx",
    // position: 'Back-end Developer',
    imageUrl: hyrox,
  },
  {
    name: "Iris Fintness Studio",
    // position: 'Back-end Developer',
    imageUrl: Iris,
  },
  {
    name: "Kiara",
    // position: 'Mobile Developer',
    imageUrl: Kiara,
  },
  {
    name: "Kids O Kids",
    // position: 'Mobile Developer',
    imageUrl: kids,
  },
  {
    name: "Madurai Kadai",
    // position: 'UI/UX Designer',
    imageUrl: MaduraiKadai,
  },
  {
    name: "Mobile Bazar",
    // position: 'UI/UX Designer',
    imageUrl: mobilebazar,
  },
];
