/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import logo from "../assets/images/pgswhite.gif";
import Button from "../elements/Button";

export default function BrandIcon() {
  return (
    // <Button className="" type="link" href="/">
    //   <p className="text-theme-blue text-4xl font-medium ">
    //     Pro Global Solutions
    //     {/* <span className="text-theme-purple">studio</span> */}
    //   </p>
    // </Button>
    <Button className="" type="link" href="/">
      <img
        src={logo}
        alt="Pro Global Solutions Logo"
        style={{ height: "75px", width: "auto" }}
      />
      {/* Adjust height as needed */}
    </Button>
  );
}
