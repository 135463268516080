import Button from "elements/Button";
import React, { useState } from "react";

export default function Hireus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const handleSendMessage = () => {
    const message = `Hello, I would like to apply for the role of ${role}. My name is ${name} and my email is ${email}.`;
    const whatsappUrl = `https://wa.me/9360620595?text=${encodeURIComponent(
      message,
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="flex flex-col items-center px-10 pb-10 bg-white shadow-lg rounded-lg w-auto space-y-8 justify-center">
      {/* Contact Us Header */}
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900">Hire Us!</h1>
      </div>
      {/* Input Fields and Button */}
      <div className="flex flex-col md:flex-row md:space-x-4 w-full space-y-2 md:space-y-0">
        <input
          type="text"
          placeholder="Your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded"
        />
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded"
        />
        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded"
        >
          <option value="" disabled>
            Role
          </option>
          <option value="sales">SALES EXECUTIVE</option>
          <option value="flutter">FLUTTER DEVELOPER</option>
          <option value="react">REACT JS DEVELOPER</option>
          <option value="full">FULL STACK DEVELOPER</option>
          <option value="digital">DIGITAL MARKETING</option>
          <option value="tele">TELECALLER</option>
          <option value="video">VIDEO EDITING</option>
        </select>
      </div>
      <Button
        onClick={handleSendMessage}
        className="md:w-auto bg-theme-purple text-white mt-4 md:mt-0 border-theme-purple hover:bg-dark-theme-purple transition duration-200 font-semibold py-4 px-6 rounded-full shadow-lg"
      >
        SEND NOW
      </Button>
    </div>
  );
}
